const tutorialData = [
    {
        _id: "m0",
        tutorialNumber : 0,
        tutorialTitle : "Modules",
        tutorialOverview : "The Modules provide you with the structure and guidance to get you to What’s Next!  Work through the Modules in the order they are provided in the application. You can move through each lesson by clicking on ‘Previous Lesson’ and ‘Next Lesson.’The Discovery application works best on a Chrome browser. You can also navigate to any place in the program through the side table of contents by clicking on the "+" sign in the top left corner.",
        tutorialCategory : "",
        tutorialPoster : "thumbnail-module-1.jpg",
        tutorialLink :"https://discovery-journey-052022.herokuapp.com/",
        tutorialVideo :"",
    },
    {
        _id: "m1",
        tutorialNumber : 1,
        tutorialTitle : "Contents",
        tutorialOverview : "The Table of Contents provides you with the structure to complete Discovery to help you get to What’s Next!  Make sure to follow the program as listed.  Bookmark the Table of Contents so you can easily return to it each time you login.",
        tutorialCategory : "",
        tutorialPoster : "thumbnail-module-2.jpg",
        tutorialLink :"https://bit.ly/PSPC2022TOC",
        tutorialVideo :"",
    },
    {
        _id: "m2",
        tutorialNumber : 2,
        tutorialTitle : "Community",
        tutorialOverview : "Make sure to sign up and interact with your fellow peers in the Peppercorn Community. The Peppercorn Community is a great way to network but also get the latest resources, articles and insights during your search for What's Next! The Peppercorn Community is a separate login, you will need to sign up for.",
        tutorialCategory : "",
        tutorialPoster : "thumbnail-module-3.jpg",
        tutorialLink :"https://bit.ly/PCCOMMUNITY",
        tutorialVideo :"",
    },
 {
        _id: "m3",
        tutorialNumber : 3,
        tutorialTitle : "Coaching",
        tutorialOverview : "Through the Discovery process, you can attend weekly Discovery Coaching sessions.  These sessions take place every week on Tuesdays from 9 - 10 AM PST / 12 - 1 PM EST. If you have not received the invite for this session you  can email coach@peppercorn.ai to get added to the calendar invite.",
        tutorialCategory : "",
        tutorialPoster : "thumbnail-module-4.jpg",
        tutorialLink : "https://bit.ly/PCCPDCOACH",
        tutorialVideo :"",
    },
   {
        _id: "m4",
        tutorialNumber : 4,
        tutorialTitle : "Quizzes",
        tutorialOverview : "All exercises within Peppercorn Discovery are housed within the Quizzes application.  When you are working through Discovery, you will be directed to complete your exercises within the quizzes. You can also click on this link to go to any exercises you have completed and eventually download your results for each exercise. Your exercises (quizzes) in Discovery will open in a new window. Once you complete an exercise (quiz) return back to the page you were on before to move forward.",
        tutorialCategory : "",
        tutorialPoster : "thumbnail-module-5.jpg",
 tutorialLink: "https://discovery-quiz-052022.herokuapp.com/login?token=${parse_token}",
        tutorialVideo :"",
    },
    {
        _id: "m5",
        tutorialNumber : 5,
        tutorialTitle : "Recordings",
        tutorialOverview : "All video recordings in the Peppercorn Discovery will be in the Modules and the Recordings link.",
        tutorialCategory : "",
        tutorialPoster : "thumbnail-module-6.jpg",
        tutorialLink :"https://discovery-journey-052022.herokuapp.com/recordings",
        tutorialVideo :"",
    },
  {
        _id: "m6",
        tutorialNumber : 6,
        tutorialTitle : "Using Discovery",
        tutorialOverview : "You can always come back to the tutorial or go to Using Peppercorn Discovery for more details on using the Application.",
        tutorialCategory : "",
        tutorialPoster : "thumbnail-module-1.jpg",
        tutorialLink :"https://bit.ly/PCPD2022UD",
        tutorialVideo :"",
    }
  
     ];
  
 export default tutorialData;
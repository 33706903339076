import React from 'react'

const CloudSpinner = () => {
    return (
<div className="clouds">
	<div className="cloud s1">This will take a minute <br/> I'm loading</div>
	<div className="cloud s2"></div>
	<div className="cloud s3"></div>
	<div className="cloud s4"></div>
	<div className="cloud s5"></div>
</div>
    )
};
export default CloudSpinner;
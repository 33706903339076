// React packages and UI
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
// Global State
import { useModuleContext } from '../utils/GlobalState';
import { idbPromise } from '../utils/helpers';
import { UPDATE_LESSONS, UPDATE_MODULES, USER_DATA } from '../utils/actions';
// Components
import ParagraphsRender from '../components/ParagraphsRender';
import SideBarParagraph from '../components/Sidebar/SidebarParagraph';
import NextSectionButtonRender from '../components/NextSectionButtonRender';
import Auth from '../utils/auth';
// GraphQL API
import { GET_ALL_MODULES, QUERY_ME, GET_SELECTED_MODULE, GET_SELECTED_LESSON, GET_SELECTED_SECTION} from "../utils/queries";
import { useQuery } from '@apollo/react-hooks';

function Paragraph() {
  const { moduleNum, lessonNum, sectionNum } = useParams();
  const [urlmoduleNum, setModuleNum] = useState('0');
  const [urllessonNum, setLessonNum] = useState('0');
  const [urlsectionNum, setSectionNum] = useState('1');

  useEffect(() => {
    setModuleNum(moduleNum)
    setLessonNum(lessonNum)
    setSectionNum(sectionNum)
  }, [moduleNum, lessonNum, sectionNum])

  const [state, dispatch] = useModuleContext();
  const { modules, userdata, lessons } = state;
  
  const [xmodules, setXModules] = useState({})
  const [xuserdata, setXUserdata] = useState({})
  const [xlessons, setXLessons] = useState({})

//Queries to Server side    
      const { data: moduleData, loading: moduleLoading, } = useQuery(GET_ALL_MODULES);
      //const modules = moduleData?.module || [];

      const { data: userData, loading: userLoading } = useQuery(QUERY_ME);
      //const me = userData?.me || [];

      const { data: lessonsData, loading: lessonsLoading } = useQuery(GET_SELECTED_MODULE, {
           variables: { modNum: Number(urlmoduleNum) }
      });
      const module = lessonsData?.module || [];
  
      const { data: lessonData, loading: lessonLoading } = useQuery(GET_SELECTED_LESSON, {
          variables: { lessNum: Number(urllessonNum) }
      });
      const lesson = lessonData?.lesson || [];
  
      const { data: sectionData, loading: sectionLoading } = useQuery(GET_SELECTED_SECTION, {
          variables: { sectNum: Number(urlsectionNum) }
      });
      const section = sectionData?.section || []

    const paragraphs = section.sectionParagraph;
    const title = section.sectionTitle;
    const loggedIn = Auth.loggedIn();
    let completed = userdata.completed;

  useEffect(() => {
    // already in global store
    if (modules.length) {
      setXModules(modules);
    } 
    // retrieved from server
    else if (moduleData) {
        dispatch({
          type: UPDATE_MODULES,
          modules: moduleData.modules
        });
        moduleData.modules.forEach(module => {
          idbPromise('modules', 'put', module);
        });
      } 
      // get cache from IndexedDB
      else if (!moduleLoading) {
        idbPromise('modules','get').then(Indexedmodules => {
          dispatch({
            type: UPDATE_MODULES,
            modules: Indexedmodules
          });
        });
    };
  }, [modules, moduleData, moduleLoading, dispatch, state.modules.length]);

  useEffect(() => {
    // already in global store
    if (userdata.length) {
      setXUserdata(userdata);
    } 
    // retrieved from server
    else if (userData) {
        dispatch({
          type: USER_DATA,
          userdata: userData.me
        });
        idbPromise('userdata','put', userData.me);
    } 
    // get cache from IndexedDB
    else if (!userLoading) {
            idbPromise('userdata','get').then(userdata => {
              dispatch({
                type: USER_DATA,
                userdata: userdata
              });
            });
          }
   }, [userdata, userData, userLoading, dispatch, state.userdata.length ]);

   useEffect(() => {
    // already in global store
    if (lessons.length) {
      setXLessons(lessons);
    } 
    // retrieved from server
    else if (lessonsData) {
      dispatch({
        type: UPDATE_LESSONS,
        lessons: module
      });
    lessonsData.module.moduleLesson.forEach(module => {
        idbPromise('lessons', 'put', module);
      });
    } else if (!lessonsLoading) {
      idbPromise('lessons','get').then(lessons => {
        dispatch({
          type: UPDATE_LESSONS,
          lessons: lessons
        });
      });
    };
   }, [lessons, lessonsData, lessonsLoading, dispatch, state.lessons.length]);

  return ( 
    <div> 
        {loggedIn ? (
            
            <>
            <SideBarParagraph activemodule={module} activesections={lesson.lessonSection} activemoduleNum={urlmoduleNum} activelessonNum={urllessonNum} activesectionNum={urlsectionNum} />
        <div className="container">
            <header>
                {lessonLoading ? 
                <Spinner className="spinner" animation="grow" /> 
                    : <NextSectionButtonRender sections={lesson.lessonSection} />
                }
            </header>
            <main className="pb-4">
                <h2>{title}</h2>
                <div>{sectionLoading ? <Spinner className="spinner" animation="grow" /> : <ParagraphsRender sections={lesson.lessonSection} paragraphs={paragraphs}/>}</div>
            </main>
        </div>
       </> ) :
         ( 
          <>
            {localStorage.setItem('referring_url', window.location.href)}
            <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
          </>
         )}
    </div>
)
};

export default Paragraph;

import React from 'react';
import { Spinner } from 'react-bootstrap';
import { SEARCH_QUERY, GET_PARAGRAPHS } from '../utils/queries';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import SearchRender from '../components/SearchRender';
import Auth from '../utils/auth';

const SearchResults = () => {
  const { searchTerm } = useParams();
    // console.log({searchTerm});
  const { loading, data } = useQuery(SEARCH_QUERY, {
      variables: { search: searchTerm }
    });

  const search = data?.searchQuery || [];
    // console.log({search});
  

  const loggedIn = Auth.loggedIn();

  return ( 
      <div className="container">
        {loggedIn ?(
          <main>
              <div className="my-2">
              {data
              ? (<h4 className="text-center">Search results from: {searchTerm}</h4>)
              : (<h4 className="text-center">Loading data for search term:{searchTerm}</h4>
              )}

              <div>{loading
              ? ( <Spinner className="spinner" animation="grow" /> )
              : (<SearchRender search={search.paragraphs} searchTerm={searchTerm}/>)}
              </div>
              
          </div>
          <hr />
          </main>
            )
            : (
              <>
                {localStorage.setItem('referring_url', window.location.href)}
                <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
              </>
            )}
      </div>
  )
};


export default SearchResults;
import React from 'react';
import { GET_SELECTED_LESSON } from '../utils/queries';
import { useQuery } from '@apollo/react-hooks';
import { Spinner, ListGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Auth from '../utils/auth';

const SectionsRender = (props) => {

  const {
    sections,
    moduleNum,
    lessonNum
  } = props;

  const { loading, data } = useQuery(GET_SELECTED_LESSON, {
    variables: { lessNum: Number(lessonNum) }
  });

  if (!sections.length) {
    return <h1>There are no sections in this Module!</h1>;
  };

  const lesson = data?.lesson || [];
  const loggedIn = Auth.loggedIn();
  const sections1 = lesson.lessonSection;
  console.log({sections});
  console.log({lesson});
  console.log({sections1});

  return (
    <div>
      {loading
    ? ( <Spinner className="spinner" animation="grow" /> ): ''}
      {loggedIn ? ( 
      <>
      {sections1 &&
        sections1.map((section) => (
          <div>
            <ListGroup className="col-auto" variant="flush">
            <Link to={`/module/${moduleNum}/lesson/${lessonNum}/section/${section.sectionNumber}`}>
              <ListGroup.Item 
              variant="custom" 
              className="list-group-item-custom mx-2 my-2" 
              key={section._id}
              >
              <small className="text-muted">Section Number {section.sectionNumber}: ... {section.sectionTitle}</small>
              </ListGroup.Item>
              </Link>
            </ListGroup>
          </div> 
        ))}
      </> ) : (
        
        <>
          {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
        </>
      )}
      </div>
  );
};

export default SectionsRender;

import React from 'react';
import { Link, useParams } from "react-router-dom";
import {  Card } from 'react-bootstrap';
import Auth from '../utils/auth';
import { GET_SELECTED_MODULE } from '../utils/queries';
import { useQuery } from '@apollo/react-hooks';

const ParagraphsRender = (props) => {
  const {
    paragraphs,
    sections,
  } = props;
 
  // useParams to get the current position
  const currentPage = useParams();

  // Render next and previous buttons
  const ButtonChange = (props) => {
    const {
      paragraphRef
    } = props;
    const moduleNum = Number(paragraphRef.split('.')[0]);
    const sectNum = Number(paragraphRef.split('.')[2]);
    const { loading: loadingData, data: lessonData } = useQuery(GET_SELECTED_MODULE, {
      variables: { modNum: Number(moduleNum) }
    });
    const module = lessonData?.module || [];

    const findlessNum = () => {
      let lessNum = 20;
      module.moduleLesson && module.moduleLesson.forEach(lesson => {
        lesson.lessonSection && lesson.lessonSection.forEach(section => {
          // if (paraNum !== (section.sectionNumber))
          if (sectNum !== (section.sectionNumber))
                {console.log("section not found", section.sectionNumber, lesson.lessonNumber)
                  }
          else
                {lessNum = lesson.lessonNumber }
        })
      });
      return lessNum;
      };

    // list all sections
    if(!sections){
      return ('')
    }
    const allSections = sections.map((e) => e.sectionNumber)
    const index = allSections.indexOf(Number(currentPage.sectionNum));

    // create variables of next and previous sections
    const nextIndex = allSections[index+1];
    const prevIndex = allSections[index-1];

    if ((prevIndex == undefined) && (nextIndex == undefined)) {
      return (
        <Link className="mx-2 px-2" to={`/module/${moduleNum}/lesson/${findlessNum()+1}/section/${sectNum + 1}`}>Only 1 section, go to the next lesson</Link>
      )
    } else if(nextIndex == undefined){
      return (
      <Link className="mx-2 px-2" to={`/module/${moduleNum}/lesson/${findlessNum()+1}/section/${sectNum + 1}`}>Go to index to find the next lesson</Link>
      )
    } else if (prevIndex == undefined) {
      return (
        <Link className="mx-2 px-2" to={`/module/${moduleNum}/lesson/${findlessNum()}/section/${nextIndex}`}>Next Page</Link>
      )
    } else {
      return (
      <>
        <Link className="mx-2 px-2" to={`/module/${moduleNum}/lesson/${findlessNum()}/section/${prevIndex}`}>Previous Page</Link>
        <Link className="mx-2 px-2" to={`/module/${moduleNum}/lesson/${findlessNum()}/section/${nextIndex}`}>Next Page</Link>
      </>
      )
    };
  };

  if (!paragraphs.length) {
    return <h3>There are no paragraphs in this Section!</h3>;
  }
  const loggedIn = Auth.loggedIn();

  return (
    <div>
      <hr />
      {loggedIn ? (<>
       <div>
      {paragraphs && paragraphs.map((paragraph) => (
                <Card key={paragraph._id}>
                  <Card.Body>
                  <div className="dynamic-content-div" dangerouslySetInnerHTML= {{__html: paragraph.paragraphContent}} />
                  </Card.Body>
                  <Card.Footer className="text-center">
                    {/* peppercorn specific do not render next section previous section etc */}
                    {/* <ButtonChange paragraphRef={paragraph.paragraphRef}/> */}
                  </Card.Footer>
                </Card> 
          ))}
       </div>
       </>
     ): ( 
      <>
        {localStorage.setItem('referring_url', window.location.href)}
        <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
      </>
        )}
    </div>   
   );

  };

export default ParagraphsRender;
import React, { createContext, useContext } from "react";
import { useModuleReducer } from './reducers';

const ModuleContext = createContext();
const { Provider } = ModuleContext;

const ModuleProvider = ({ value = [], ...props }) => {
    const [state, dispatch] = useModuleReducer({
      modules: [], // array of all modules
      lessons: [], // array of all lessons inside of a module
      userdata: [],
      search: [],
    });
    // console.log({state}); // comment this in to test!
    return <Provider value={[state, dispatch]} {...props} />;
  };

const useModuleContext = () => {
    return useContext(ModuleContext);
};

export { ModuleProvider, useModuleContext };
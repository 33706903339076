import React from 'react';
import { Link } from 'react-router-dom';



const Completed = ({ completed }) => {
  if (!completed.length) {
    return <h4 className="mx-2">No completed modules Yet</h4>;
  }

  return (
    <div>
      {completed &&
        completed.map(completed => (
          <div key={completed} className="card mb-3 mx-2">

            <div className="card-body">
              <Link to={`/module/${completed}`}>
                <p>{completed} : {completed}</p>
              </Link>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Completed;
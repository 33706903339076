import { useReducer } from 'react';

import { 
    UPDATE_MODULES,
    UPDATE_LESSONS,
    USER_DATA,
 }
from '../utils/actions';

export const reducer = (state, action) => {

    switch (action.type) {
        // if action type value is the value of `UPDATE_MODULES`, return a new state object with an updated modules array
        case UPDATE_MODULES:
            return {
                ...state,
                modules: [...action.modules],
            };

        case UPDATE_LESSONS:
            return {
                ...state,
                lessons: [...state.lessons, action.module],
            };

        case USER_DATA:
            return {
                ...state,
                userdata: action.userdata
            };

        default:
            return state ? state : '';
    }
};

export function useModuleReducer(initialState) {
    return useReducer(reducer, initialState);
}
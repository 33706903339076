import React , { useState } from 'react';
import { Link } from "react-router-dom";
import {  Card, Badge, Row, Col, CardDeck } from 'react-bootstrap';
import { useModuleContext } from '../utils/GlobalState';
import Auth from '../utils/auth';
import ModalTutorial from '../modals/ModalTutorial';
import tutorialData from '../modals/tutorialData';

const ModulesRender = () => {
  const [modalShow, setModalShow] = useState(false);
  const [state] = useModuleContext();
  const { modules, userdata } = state;

  let completed = userdata.completed || [ 1, 2];
  // console.log({completed})

const handleComplete = (number) => {
  let completeForUser = completed.some(complete => complete === (number));
// console.log("module number", number, "completeForUser", completeForUser )
  return (<Badge className="pill-center mx-2 my-2"
    variant={completeForUser ? "success" : "dark"}>
      {completeForUser ? "Complete" : "Incomplete"}
      </Badge>)
};

  if (!modules.length) {
    return <h3>There are no modules in this library!</h3>;
  }

  const loggedIn = Auth.loggedIn();

  return (
    <div>
        {loggedIn ? (<div className="d-flex justify-content-end py-4 mx-4">
              <button className="btn-tutorial " onClick={() => setModalShow(true)}>Tutorial</button>
          </div>): <hr />}
      <Row>
      {modules &&
        modules.map((module) => (
        <Col key={module.moduleNumber}>
           <CardDeck>
            <Link to={`/module/${module.moduleNumber}`}>
            <Card className="text-center modulecard">
               {loggedIn
                ? (handleComplete(module.moduleNumber))
                : (<Badge className="pill-center mx-2 my-2" variant="custom">Login Required</Badge>
                )}      
            <Card.Img className="modulecard-img" variant="top" src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${module.modulePoster}`} alt={`module-image:${module.moduleTitle}`} />
            <Card.Body>
              <Card.Title>{module.moduleNumber} : {module.moduleTitle}</Card.Title>
            </Card.Body>
            <Card.Footer>
               <small className="text-muted">Click to open this Module</small>
            </Card.Footer>
          </Card>
          <br />
        
          </Link>
          </CardDeck>
          </Col>             
        ))}
        </Row>
        <ModalTutorial
        data={tutorialData}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ModulesRender;

import React from 'react';
import SectionsRender from '../components/SectionsRender';
import { GET_SELECTED_LESSON } from '../utils/queries';
import { useQuery } from '@apollo/react-hooks';
import { Spinner } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import Auth from '../utils/auth';

const Section = () => {
    const { moduleNum, lessonNum } = useParams();

    const { loading, data } = useQuery(GET_SELECTED_LESSON, {
        variables: { lessNum: Number(lessonNum) }
      });

    //   useEffect(() => {
    //     const lessonInModule = modules.find((ModuleItem) => ModuleItem._id === moduleId);
    //     console.log({lessonInModule})
    //     if(lessonInModule) {
    //         dispatch({
    //             type: UPDATE_MODULE_LESSONS,
    //             moduleId: moduleId,
    //             modules: lessonData
    //         });
           
    //     }
    //   }, [lessonData, dispatch]);
  
    //   console.log(modules)

    const lesson = data?.lesson || [];
    // console.log(lesson);
    const loggedIn = Auth.loggedIn();

    return ( 
        <div className="container">
            {loggedIn ? (
            <main>
                <div>{loading
                ? ( <Spinner className="spinner" animation="grow" /> )
                : (<h4 className="text-center">There are {lesson.lessonSection.length} sections in this lesson.</h4>)}
                </div>
                
                <hr />
                <div className="my-2">
                {data
                ? (<SectionsRender sections={lesson.lessonSection} moduleNum={moduleNum} lessonNum={lessonNum} />)
                : ''}
            </div>
            <hr />
            </main>
            ): (
                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
                </>
            )}
        </div>
    )
};

export default Section;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, Accordion, Form, Button, Row, Col } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';
import { ADD_USER } from '../utils/mutations';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const Signup = () => {
  const [formState, setFormState] = useState({ username: '', email: '', password: '', uniqueid: ''});
  const [signedUp, setSignedUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorName, setErrorName] = useState('');
   const [validated] = useState(false);
  //useMutation Hook creates and prepares a JS function that wraps around mutation code and returns it in form of addUser function
  const [addUser, { error }] = useMutation(ADD_USER);
  const eye = <FontAwesomeIcon icon={faEye} />;

  useEffect(() => {
    // setSignedUp(true);
  }, [setFormState]);

  // update state based on form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // Show/ Hide password
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // submit form (notice the async!)
  const handleFormSubmit = async (event) => {
    event.preventDefault();
 

    // use try/catch instead of promises to handle errors
    try {
      // execute addUser mutation and pass in variable data from formState
      //upon success, destructure data object from response of mutation and console log
      const { data } = await addUser({
        variables: { ...formState }
      });
      setSignedUp(true)
      // Auth.login(data.addUser.token);
    } catch (e) {
        setErrorName(`${e}`);
        console.error(e);
        setShowAlert(true);

    }
   
  };

  return (
    <div className="container">
         <Col className="logincard" md={{ span: 6, offset: 3}}>
          <main className='flex-row justify-center mb-3'>
            {signedUp ? (
              <h3 className="text-center my-2">Almost there. Please check your email and confirm your account before you login.</h3>
            ) : (
              <>
                  <Link to="/login" className="btn btn-link">
                  ← Go to Login
                  </Link>
                  <h3 className="text-center my-2">Sign Up</h3>
                  <Card>
                    <Card.Body className="text-center">
                          <Link to="/Terms" className="btn btn-link">
                            By signing up you accept our terms and policies
                          </Link>
                    </Card.Body>
                  </Card>
                <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                  <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant='danger'>
                      {errorName.split(":")[2]}
                  </Alert>
                <Form.Group>
                    <Form.Label htmlFor='email'>Email</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Your email address'
                        name='email'
                        onChange={handleChange}
                        value={formState.email}
                        required
                    />
                    <Form.Control.Feedback type='invalid'>Email is required!</Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group>
                    <Form.Label htmlFor='password'>Password</Form.Label>
                    <div className="pass-wrapper">
                    <Form.Control
                        type={passwordShown ? 'text' : 'password'}
                        placeholder='Your password'
                        name='password'
                        onChange={handleChange}
                        value={formState.password}
                        required
                    />
                    <i className={passwordShown ? 'i-pass-show' : 'i-pass'} onClick={togglePasswordVisiblity}>{eye}</i>
                    </div>
                    <Form.Control.Feedback type='invalid'>Password is required!</Form.Control.Feedback>
                    
                </Form.Group>

    <Row> 
                   
                   <Form.Group as={Col}>
                       <Form.Label htmlFor='username'>Username</Form.Label>
                       <Form.Control
                           type='text'
                           as="input"
                           placeholder='Username'
                           name='username'
                           onChange={handleChange}
                           value={formState.username}
                           required
                       />
                       <Form.Control.Feedback type='invalid'>Username is required!</Form.Control.Feedback>
                   </Form.Group>

                   <Form.Group as={Col}  htmlFor="uniqueid">
                    <Form.Label>Unique ID</Form.Label>
                    <Form.Control 
                    type="uniqueid"
                    as="input"
                    placeholder="ID#" 
                    name="uniqueid"
                    onChange={handleChange} 
                    value={formState.uniqueid}  
                    required
                    />
                    <Form.Text className="text-muted">
                      Authenticate course credentials.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                    This number is located in your Bootcamp login
                    </Form.Control.Feedback>
                    </Form.Group>

                    {/*<Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="you agree to our terms and policies" required />
                        <Form.Control 
                          type="checkbox"
                          as="input"
                          name=" "
                          onChange={handleChange} 
                          value=" "
                          required
                        />
                          <Form.Text className="text-muted">
                          <Link to="/Terms" className="btn btn-link">
                            see link to terms and policies
                          </Link>
                          </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          You must accept terms and policies to Signup
                        </Form.Control.Feedback>
                     </Form.Group>*/}

    </Row>

              <Button 
                className='btn d-block w-100' 
                type='submit'
                variant='success'
              >
                Sign Up
              </Button>

                {error && <div>Sign Up failed<span></span></div>}

            </Form>
            </>
              )}
            </main>
            <Accordion>
            <Card>
              <Card.Body className="text-center">
                <Accordion.Toggle as={Button} variant="light" eventKey="0">
                🔒 Do we take security seriously?
                </Accordion.Toggle>
              </Card.Body>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="text-center">
                We use a 2048-bit SSL Certificate when you send your data and salted password-hashing up to 72 bytes when storing your password. In short, yes we are secure.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            </Accordion>
            </Col>
            </div>
  );
};

export default Signup;
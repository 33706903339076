import React, {useState, createRef} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useModuleContext } from '../../utils/GlobalState';
import {  ToggleButtonGroup, ToggleButton, Badge } from 'react-bootstrap'
import styled from 'styled-components';
import ToolTipCustom from '../ToolTipCustom';


const StyledSideNav = styled.div`   
position: absolute;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
height: 100%;
width: 130px;     /* Set the width of the sidebar */
z-index: 1;      /* Stay on top of everything */
top: 3.4em;      /* Stay at the top */
background-color: #C4C4C4; /* light grey */
overflow-x: hidden;     /* Disable horizontal scroll */
padding-top: 30px;
`;

const StyledNavItem = styled.div`
height: 70px;
width: 130px; /* width must be same size as NavBar (95)to center */
text-align: left; /* Aligns <a> inside of NavIcon div */
margin-bottom: 0;   /* Puts space between NavItems */
a {
    font-size: 0.7rem;
    color: ${(props) => props.active ? "#303551" : "#FFFFFF"};
    :hover {
        opacity: 0.7;
        text-decoration: none; /* Gets rid of underlining of icons */
    }  
}
`;

const NavIcon = styled.div`

`;

const ClearStyledSideNav = styled.div`   
position: absolute;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
height: 100%;
width: 75px;     /* Set the width of the sidebar */
z-index: 1;      /* Stay on top of everything */
top: 3.4em;      /* Stay at the top */
background-color: Transparent; /* light grey */
overflow-x: hidden;     /* Disable horizontal scroll */
padding-top: 30px;
`;


const SideBarT = () => {
  const [toggle, setToggle] = useState(false);
  const [state] = useModuleContext();
  const { modules, userdata } = state;
  const location = useLocation();
  let completed = userdata.completed;

  const handleChange = () => setToggle(!toggle);
  //console.log({toggle});
  //console.log(location.pathname)

  const handleComplete = (number) => {
  let completeForUser = completed.some(complete => complete === (number));
  return (<Badge className="pill-center mx-2 my-2"
    variant={completeForUser ? "SidebarComplete" : "Sidebar"}>
     {number}
      </Badge>)
  };


if (!modules.length) 
{
    return( 
    <h8>SideNav</h8>
  )}
else 
{
  return(
    <div>
      {toggle ?  
    <StyledSideNav className="text-center">
    <ToggleButtonGroup type="checkbox" onChange={handleChange}>
        <ToggleButton variant='custom'>
            <ToolTipCustom
                        placement='right'
                        tooltipContent='Hide'
                        overlayContent={<i class="far fa-times-circle fa-w-16 fa-2x"></i>}
                        tooltipVariant='custom'
                        badgeVariant='clear'
                  />
        </ToggleButton>
    </ToggleButtonGroup>

      {/*{items && items.map((item, index) => (
          <StyledNavItem key={index} active={item.path === location.pathname}>
                <Link to={item.path} className={StyledNavItem.css}>
                  <ToolTipCustom
                        placement='right'
                        tooltipContent={item.name}
                        overlayContent={<i class={item.css}></i>}
                        tooltipVariant='custom'
                        badgeVariant='clear'
                  />

                </Link>
          </StyledNavItem>
      ))}*/}
        {modules && modules.map((module) => (
         <StyledNavItem key={module.moduleNumber} active={`/module/${module.moduleNumber}` === location.pathname}>
          <Link to={`/module/${module.moduleNumber}`} className={StyledNavItem.css}>
          {(handleComplete(module.moduleNumber))}
          {module.moduleTitle.slice(0,10)}...
            {/*<ToolTipCustom
                  placement='right'
                  tooltipContent={module.moduleTitle}
                  overlayContent={<i class="fas fa-circle">{module.moduleNumber}</i>}
                  tooltipVariant='custom'
                  badgeVariant='clear'
            />*/}
          </Link>
         </StyledNavItem>
          ))}

      </StyledSideNav>
    :
    <ClearStyledSideNav className="text-center">
    <ToggleButtonGroup type="checkbox"  onChange={handleChange}>
        <ToggleButton variant='custom'>
            <ToolTipCustom
                        placement='right'
                        tooltipContent='Show'
                        overlayContent={<i class="fas fa-plus-circle fa-w-16 fa-2x"></i>}
                        tooltipVariant='custom'
                        badgeVariant='clear'
                  />
        </ToggleButton>
      </ToggleButtonGroup>

    </ClearStyledSideNav>  
    }
    </div>   
  )
}};

export default SideBarT;
import React from 'react';
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';


const ToolTipCustom = (props) => {
  const {
    placement,
    tooltipContent,
    overlayContent,
    tooltipVariant,
    badgeVariant
  } = props;

    return (
        <>
            <OverlayTrigger
                key= {placement}
                placement={placement}
                overlay={
                    <Tooltip id={`tooltip-${placement}`} variant= {tooltipVariant}>
                        {tooltipContent}
                    </Tooltip>
                }         
            >
                <Badge className="pill-center mx-2 my-2" variant= {badgeVariant}>
                    {overlayContent}
                </Badge>
            </OverlayTrigger>
        </>
    )
};
export default ToolTipCustom;
import React, {useState, createRef} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useModuleContext } from '../../utils/GlobalState';
import {  ToggleButtonGroup, ToggleButton, Badge, Accordion, Card } from 'react-bootstrap'
import styled from 'styled-components';
import ToolTipCustom from '../ToolTipCustom';
import SidebarComponentLesson from './SidebarComponentLesson'

const StyledSideNav = styled.div`   
position: absolute;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
height: 100%;
width: 130px;     /* Set the width of the sidebar */
z-index: 1;      /* Stay on top of everything */
top: 3.4em;      /* Stay at the top */
background-color: #C4C4C4; /* light grey */
overflow-x: hidden;     /* Disable horizontal scroll */
padding-top: 60px;
:hover {
  width: 200px;     /* increase width of the sidebar */
}  
`;

const StyledNavItem = styled.div`
height: 70px;
width: 130px; /* width must be same size as NavBar (95)to center */
text-align: left; /* Aligns <a> inside of NavIcon div */
margin-bottom: 0;   /* Puts space between NavItems */
:hover {
  width: 200px;     /* increase width of the sidebar */
}  
a {
    font-size: 0.7rem;
    color: ${(props) => props.active ? "#303551" : "#FFFFFF"};
    :hover {
        opacity: 0.7;
        text-decoration: none; /* Gets rid of underlining of icons */
    }  
}
`;


const ClearStyledSideNav = styled.div`   
position: absolute;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
height: 100%;
width: 75px;     /* Set the width of the sidebar */
z-index: 1;      /* Stay on top of everything */
top: 3.4em;      /* Stay at the top */
background-color: Transparent; /* light grey */
overflow-x: hidden;     /* Disable horizontal scroll */
padding-top: 30px;
`;

const SideBarParagraph = (props) => {
  const {
    activemodule,
    activesections,
    activemoduleNum,
    activelessonNum,
    activesectionNum
  } = props;

  const [toggle, setToggle] = useState(false);
  const [state] = useModuleContext();
  const { modules, userdata } = state;
  const location = useLocation();
  const currentPage = useParams();
  let completed = userdata.completed;

  const handleChange = () => setToggle(!toggle);
  // console.log({toggle});
  // console.log(location.pathname)

  const handleComplete = (number) => {
  let completeForUser = completed.some(complete => complete === (number));
  return (<Badge className="pill-center mx-2 my-2"
    variant={completeForUser ? "SidebarComplete" : "Sidebar"}>
     {number}
      </Badge>)
  };

  const checkModule = (num) => {
    let compareNum =  parseInt(activemoduleNum, 10);
    let checkActiveModule = (compareNum === num);

    return (
      <>
      {checkActiveModule 
        ?
        <SidebarComponentLesson activemodule={activemodule} activesections={activesections} activemoduleNum={activemoduleNum} activelessonNum={activelessonNum} activesectionNum={activesectionNum}/> 
       :
       " "
      }
      </>
    )
  };

  const checkModuleColor = (num) => {
    let compareNum =  parseInt(activemoduleNum, 10);
    let checkActiveModule = (compareNum === num);
    let sidebarAccordionStyle;
    {checkActiveModule 
      ?
          sidebarAccordionStyle = {
          padding: "0rem 0rem",
          marginBottom: "0",
          backgroundColor: "rgba(0,0,0,.1)",
          borderBottom: "1px solid rgba(0,0,0,.125)"
        }
        
    :
          sidebarAccordionStyle = {
          padding: "0rem 0rem",
          marginBottom: "0",
          backgroundColor: "rgba(0,0,0,0)",
          borderBottom: "1px solid rgba(0,0,0,0)"
        }
     
      }
      return sidebarAccordionStyle;
    };

    const checkAccordionIcon = (num) => {
      let compareNum =  parseInt(activemoduleNum, 10);
      let checkActiveModule = (compareNum === num);
      let accordionicon;
      {checkActiveModule 
        ?
          accordionicon =  <i class="fas fa-caret-right"></i>             
      :
          accordionicon =  " "       
        }
        return accordionicon;
      };



 
if (!modules.length) 
{
    return( 
    <h8>SideNav</h8>
  )}
else 
{
  return(
  <div>
      {toggle ?  
    <StyledSideNav className="text-center">
      <ToggleButtonGroup type="checkbox" onChange={handleChange}>
        <ToggleButton variant='custom'>
            <ToolTipCustom
                        placement='right'
                        tooltipContent='Hide'
                        overlayContent={<i class="far fa-times-circle fa-w-16 fa-2x"></i>}
                        tooltipVariant='custom'
                        badgeVariant='clear'
                  />
        </ToggleButton>
      </ToggleButtonGroup>

{/* this accordion is to render the side nav items*/}
<Accordion className="card-sidebarmodule">
    {modules && modules.map((module) => (
    <Card className="card-sidebarmodule" key={module.moduleNumber} active={module.moduleNumber === activemoduleNum}>
      
      <Accordion.Toggle 
        as={Card.Header} 
        eventKey={`${module.moduleNumber}`} 
        style={(checkModuleColor(module.moduleNumber))}
        >
        <Link className = 'cardsidebarmodule-link' to={`/module/${module.moduleNumber}`}>
            {(handleComplete(module.moduleNumber))}                                                                                                                                                                                                                                                                                                                                                                                                                                    
        </Link>
            {module.moduleTitle.slice(0,10)}... 
            {' '} 
            {(checkAccordionIcon(module.moduleNumber))}  
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={`${module.moduleNumber}`}>
        <>
        {(checkModule(module.moduleNumber))}
        </>
      </Accordion.Collapse>
    </Card>
  ))}
</Accordion>

    </StyledSideNav>
    :
    <ClearStyledSideNav className="text-center">
      <ToggleButtonGroup type="checkbox"  onChange={handleChange}>
        <ToggleButton variant='custom'>
            <ToolTipCustom
                        placement='right'
                        tooltipContent='Show'
                        overlayContent={<i class="fas fa-plus-circle fa-w-16 fa-2x"></i>}
                        tooltipVariant='custom'
                        badgeVariant='clear'
                  />
        </ToggleButton>
      </ToggleButtonGroup>
    </ClearStyledSideNav>  
    }
  </div>   
  )
}};

export default SideBarParagraph;
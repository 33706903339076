import React, { useEffect } from "react";
import { useQuery } from '@apollo/react-hooks';
import { Link, useHistory } from "react-router-dom";
import {  ListGroup, ResponsiveEmbed, Button, Card, Badge, Row, Col, CardDeck} from 'react-bootstrap';
import Auth from '../utils/auth';
import { GET_ALL_MODULE_VIDEOS } from "../utils/queries";
import { useModuleContext } from '../utils/GlobalState';
import { UPDATE_MODULES } from '../utils/actions';
import { idbPromise } from '../utils/helpers';
const loggedIn = Auth.loggedIn();

//Recording Component
const Recording = (recordingId) => {
  const [state, dispatch] = useModuleContext();
  const {loading, data} = useQuery(GET_ALL_MODULE_VIDEOS);
  // console.log(data);
  
  useEffect(() => {
    if (data) {
      dispatch({
        type: UPDATE_MODULES,
        modules: data.modules
      });
      data.modules.forEach(module => {
        idbPromise('modules', 'put', module);
      });
    } else if (!loading) {
      idbPromise('modules','get').then(modules => {
        dispatch({
          type: UPDATE_MODULES,
          modules: modules
        });
      });
    };
  },[data, loading, dispatch,]);

  const videos = data?.modules || [];
  
  return (
    <div className="container">
       <header>
        <h1 className="text-center bold text-6xl">List Of Recordings</h1>
      </header>
      <hr />
      {loggedIn ? ( 
      <>
        {videos &&
        videos.map((module) => (
          <div>
            <ListGroup className="col-auto" variant="flush">
            <Link to={`recordings/${module.moduleNumber}`}>
              <ListGroup.Item 
              variant="custom" 
              className="list-group-item-custom mx-2 my-2" 
              key={module.moduleNumber}
              >
              {module.moduleTitle}
              </ListGroup.Item>
              </Link>
            </ListGroup>
          </div> 
        ))}
      </> ) : (
      
      <>
        {localStorage.setItem('referring_url', window.location.href)}
        <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
      </>
      )}
      </div>
  );
}

//Video Component
const Video = ({ match: { params: {recordingId} }}) => {
  const [state] = useModuleContext();
  const { modules } = state;
  let history = useHistory();
  let compareNum =  parseInt(recordingId, 10);
 
  if(!modules.length) {
    return(
      <div className="container">
      <h3>The recording you requested is not loading.</h3>
      <hr />
      <h4 className="mx-2">Go back to recordings and try again.
      <Button className="mx-2" variant="success" onClick={() => history.goBack()}>Back to Recordings</Button>
      </h4>
     </div>
    )
  };


  return (
    <div className="container">
      {modules && modules.map((module) => (
        <div key={module._id}>
          {(module.moduleNumber === compareNum) ?
          (
            <div>
              <>
                <h3 className="bold text-6xl">
                   Videos for {module.moduleTitle}
                </h3>
                    <h4>Welcome to the recordings page. Press the play button to start the recording.</h4>
                    <hr />
                      <ul>
        {module.moduleVideo && module.moduleVideo.map(({ videoNumber, videoTitle, videoContent }) => (
            <div key={videoNumber}>
              <h4>{videoTitle}</h4>
              <ResponsiveEmbed className="recording-container" aspectRatio="16by9"  key={videoNumber}>
                          <iframe
                              className="my-2"
                              title={videoTitle}
                              width="560"
                              height="315"
                              src={ videoContent}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen>
                          </iframe>
              </ResponsiveEmbed>
              <br />
              <hr />
            </div>
          ))}
                    </ul>
            </> 
          </div>
          )
          :
          (
            <>

            </>
          )
      }
        </div>
        ))}
    </div>
    )
};

//Repository Link Data
const repositories = [
  {
       resourceNumber : 0,
       resourceLink : "peppercorn-general",
       resourceTitle : "Peppercorn General",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-1.jpeg",
   }, 
  {
       resourceNumber : 1,
       resourceLink : "events",
       resourceTitle : "Events",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-2.jpeg",
   },
  {
       resourceNumber : 2,
       resourceLink : "job-board",
       resourceTitle : "Job Board",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-3.jpeg"
   },
  {
       resourceNumber : 3,
       resourceLink : "asks-offers",
       resourceTitle : "Ask / Offers",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-4.jpeg"
   },
  {
       resourceNumber : 4,
       resourceLink : "all-things-leadership",
       resourceTitle : "All Things Leadership",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-5.jpeg"
   },
  {
       resourceNumber : 5,
       resourceLink : "career-growth",
       resourceTitle : "Career Growth",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-6.jpeg"
   },
  {
       resourceNumber : 6,
       resourceLink : "career-pivot",
       resourceTitle : "Career Pivot",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-7.jpeg"
   },
  {
       resourceNumber : 7,
       resourceLink : "culture",
       resourceTitle : "Culture",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-8.jpeg"
   },
  {
       resourceNumber : 8,
       resourceLink : "health-wellness",
       resourceTitle : "Health & Wellness",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-9.jpeg"
   },
  {
       resourceNumber : 9,
       resourceLink : "ideal-position",
       resourceTitle : "Ideal Position",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-10.jpeg"
   },
  {
       resourceNumber : 10,
       resourceLink : "interview-resources",
       resourceTitle : "Interview Resources",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-11.jpeg"
   },
  {
       resourceNumber : 11,
       resourceLink : "job-hunting-private",
       resourceTitle : "Job Hunting",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-12.jpeg"
   },
  {
       resourceNumber : 12,
       resourceLink : "networking",
       resourceTitle : "Networking",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-13.jpeg"
   },
  {
       resourceNumber : 13,
       resourceLink : "returning-back-after-a-gap",
       resourceTitle : "Returning after a gap",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-14.jpeg"
   },
  {
       resourceNumber : 14,
       resourceLink : "offers-negotiation-private",
       resourceTitle : "Offers & Negotiation",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-15.jpeg"
   },
  {
       resourceNumber : 15,
       resourceLink : "self-awareness",
       resourceTitle : "Self Awareness",
       resourceOverview : "",
       resourceCategory : "Category1",
       resourceContent : "thumbnail-community-16.jpeg"
   }
  ];
  

//Resource Component
const Resource = () => {
  
  return (
    <div className="container">
       <header>
        <h1 className="text-center bold text-6xl">Community Resources</h1>
      </header>
      <hr />
      {loggedIn ? ( 
<Row>
{repositories && repositories.map((resource) => (
 <Col key={resource.resourceNumber}>
    <CardDeck>
     <Link onClick={() => window.open(`https://www.peppercorn.ai/community/${resource.resourceLink}`, "_blank")}>
     <Card className="text-center modulecard"> 

      <Badge className="pill-center mx-2 my-2" variant="dark"> Community </Badge>
                  
     <Card.Img className="modulecard-img" variant="top" src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${resource.resourceContent}`} alt={`module-image:${resource.resourceTitle}`} />
     <Card.Body>
       <Card.Title>{resource.resourceNumber} : {resource.resourceTitle}</Card.Title>
     </Card.Body>
     <Card.Footer>
        <small className="text-muted">Click to go here</small>
     </Card.Footer>
   </Card>
   <br />
 
   </Link>
   </CardDeck>
   </Col>             
 ))}
 </Row>

      ) : (
        <>
          {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
        </>

      )}
      </div>
  );
};


//Unsolved Component
const Unsolved = ({ 
  match: {
    params: { resourceId }
  }
}) => {
  let history = useHistory();
  if(!repositories.length) {
    return(
      <div>
      <h3>The repository you requested is not loading.</h3>
      <hr />
      <h4 className="mx-2">Please go back and try again.
      <Button className="mx-2" variant="success" onClick={() => history.goBack()}>Back to Resources</Button>
      </h4>
     </div>
    )
  }

  return(
  <div className="container">
    <h6 className="bold text-6xl">
      Community Resources: {repositories[resourceId].resourceTitle}
    </h6>
    <hr />
    <ul>
      
          <div>
            <h4>{repositories[resourceId].resourceTitle}  <a href={`https://www.peppercorn.ai/community/${repositories[resourceId].resourceTitle}`} target="_blank" rel="noopener noreferrer">community resources</a> </h4>
          
            <ResponsiveEmbed className="recording-container" aspectRatio="16by9">
                        <iframe
                            className="my-2"
                            title={repositories[resourceId].resourceTitle}
                            width="560"
                            height="315"
                            src={ `https://discovery-journey.s3.us-east-2.amazonaws.com/${repositories[resourceId].resourceContent}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
            </ResponsiveEmbed>

            <br />
            <hr />
          </div>
        
    </ul>
  </div>
  );
};


export { Resource, Recording, Video, Unsolved };

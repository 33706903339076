import React from 'react';
import { Accordion, Card, Badge } from 'react-bootstrap';
import SidebarSectionAccordion from './SidebarSectionAccordion';

const SidebarLessonAccordion = (props) => {
  const {
    activemodule,
    activesections,
    activemoduleNum,
    activelessonNum,
    activesectionNum
  } = props;

  const checkModuleColor = (num) => {
    let compareNum =  parseInt(activelessonNum, 10);
    let checkActiveModule = (compareNum === num);
    let sidebarAccordionStyle;
    {checkActiveModule 
      ?
          sidebarAccordionStyle = {
          padding: "0rem 0rem",
          marginBottom: "0",
          backgroundColor: "rgba(0,0,0,.1)",
          borderBottom: "1px solid rgba(0,0,0,.125)"
        }
        
    :
          sidebarAccordionStyle = {
          padding: "0rem 0rem",
          marginBottom: "0",
          backgroundColor: "rgba(0,0,0,0)",
          borderBottom: "1px solid rgba(0,0,0,0)"
        }
     
      }
      return sidebarAccordionStyle;
    };

  if (!activemodule.moduleLesson) {
    return <h3>There are no lessons in this Module!</h3>;
  }
  else
  {

{/* this accordion is to render the lesson side nav items*/}
  return (
<Accordion className="card-sidebarmodule">
  {activemodule.moduleLesson && activemodule.moduleLesson.map((lesson) => (
    <Card className="card-sidebarmodule" key={lesson._id} >
      
      <Accordion.Toggle 
        as={Card.Header} 
        eventKey={`${lesson._id}`} 
        style={(checkModuleColor(lesson.lessonNumber))}
        >
            Lesson {lesson.lessonNumber} 
            {' '} 
            {' '}  
            <i class="fas fa-caret-right"></i>                                                                                                                                                                                                                                                                                                                                                                                                                           
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={`${lesson._id}`}>
        <>
        <SidebarSectionAccordion  activesections={lesson.lessonSection} activemoduleNum={activemoduleNum} activelessonNum={activelessonNum} activesectionNum={activesectionNum} />
        </>
      </Accordion.Collapse>
    </Card>
  ))}
</Accordion>
  );

};
};
export default SidebarLessonAccordion;
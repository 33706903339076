import React from 'react';
import { useParams, Link } from "react-router-dom";
import { Pagination, Button} from 'react-bootstrap';

const NextSectionButtonRender = (props) => {
  
  /*const {
    sections,
    moduleNum,
    lessonNum,
    sectionNum
  } = props;*/

  const {
    sections
  } = props;

  const { moduleNum, lessonNum, sectionNum } = useParams();

  if (!sections.length) {
    return <h1>There are no sections in this Module!</h1>;
  }


const ButtonStyle ={
  backgroundColor: "transparent",
  borderColor: "transparent",
  padding: "0rem 0rem",
  borderRadius: "50%",
  border: "0px"
};

  return (
    <div>
      <h6 style= {{textAlign: "center"}}>There are {sections.length} sections in this lesson:{lessonNum}.</h6>

      <div>
        <Pagination className="pagination">
          {sections && sections.map((section) => (
            <>
            <Pagination.Item className="pageline-item"active={section.sectionNumber == sectionNum} key={section._id}>
                  {"   "}
            </Pagination.Item>
            <Button style={ButtonStyle}>
              <Pagination.Item className="page-item"  active={section.sectionNumber == sectionNum} key={section._id}>
              <Link className="page-link" to={`/module/${moduleNum}/lesson/${lessonNum}/section/${section.sectionNumber}`}>
               
                {section.sectionNumber}
           
              </Link>
            </Pagination.Item>
            </Button>
            </>
          ))}
        </Pagination>
      </div>
    </div>
  );
};

export default NextSectionButtonRender;

// React packages and UI
import React from 'react';
// Components
import SidebarLessonAccordion from './SidebarLessonAccordion';



//pulls lesson data and calls lesson Accordion
const SidebarComponentLesson = (props) => {
    const {
      activemodule,
      activesections,
      activemoduleNum,
      activelessonNum,
      activesectionNum
    } = props;



    return (
        <div>   
                {/*renders heading of module information. may be used to enable disable accordion?*/}
                        <SidebarLessonAccordion
                          activemodule={activemodule} activesections={activesections} activemoduleNum={activemoduleNum} activelessonNum={activelessonNum} activesectionNum={activesectionNum}
                        />
        </div> 
    )
};

export default SidebarComponentLesson;


// import pages
import Home from './pages/Home';
import Lesson from './pages/Lesson';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ConfirmEmail from './pages/ConfirmEmail'
import Section from './pages/Section';
import Paragraph from './pages/Paragraph';
import Profile from './pages/Profile';
import Terms from './pages/Terms';
import NoMatch from './pages/NoMatch';
import Search from './pages/Search';
import { Resource, Recording, Video, Unsolved }from './components/index';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';

// export each page as generated API 
export default [
  { path: "/", name: "Modules ", Component: Home },
  { path: "/module/:moduleNum", name: `ᐳ Selected Module `, Component: Lesson },
  { path: "/module/:moduleNum/lesson/:lessonNum", name: "ᐳ Selected Lesson ", Component: Section },
  { path: "/module/:moduleNum/lesson/:lessonNum/section/:sectionNum", name: "ᐳ Selected Section ", Component: Paragraph },
  { path: "/recordings", name: "ᐳ Recordings ", Component: Recording },
  { path: "/recordings/:recordingId", name: "ᐳ Videos ", Component: Video },
  { path: "/resource", name: "ᐳ Community Pages ", Component: Resource },
  { path: "/resource/:resourceId", name: "ᐳ Category ", Component: Unsolved },
  { path: "/login", name: "ᐳ Login ", Component: Login },
  { path: "/signup", name: "ᐳ Signup ", Component: Signup },
  { path: "/Terms", name: "ᐳ Terms ", Component: Terms },
  { path: "/profile/:username?", name: "ᐳ Profile ", Component: Profile },
  { path: "/search/:searchTerm",name: "ᐳ Search ", Component: Search},
  { path: "/login/forgot-password", name: "ᐳ Forgot Password", Component: ForgotPassword },
  { path: "/login/reset-password", name: "ᐳ Reset Password", Component: ResetPassword },
  { path: "/confirm/user/:idToken", name: "ᐳ Confirm Email", Component: ConfirmEmail },
  { name: "No match", Component: NoMatch },
];
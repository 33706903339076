import React, { useEffect }from 'react';
import { Spinner } from 'react-bootstrap';
import CloudSpinner from '../components/CloudSpinner';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_MODULES, QUERY_ME } from '../utils/queries';
import ModulesRender from '../components/ModulesRender';
import { useModuleContext } from '../utils/GlobalState';
import { UPDATE_MODULES, USER_DATA } from '../utils/actions';
import { idbPromise } from '../utils/helpers';

const Home = () => {

  const [state, dispatch] = useModuleContext();

  const { loading: moduleLoading, data: moduleData } = useQuery(GET_ALL_MODULES);
  const { loading: userLoading, data: userData } = useQuery(QUERY_ME);

  useEffect(() => {

    if (moduleData) {
      dispatch({
        type: UPDATE_MODULES,
        modules: moduleData.modules
      });
      moduleData.modules.forEach(module => {
        idbPromise('modules', 'put', module);
      });
    } else if (!moduleLoading) {
      idbPromise('modules','get').then(modules => {
        dispatch({
          type: UPDATE_MODULES,
          modules: modules
        });
      });
    };

    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me
      });
    idbPromise('userdata','put', userData.me);
    } else if (!userLoading) {
      idbPromise('userdata','get').then(userdata => {
        dispatch({
          type: USER_DATA,
          userdata: userdata
        });
      });
    }
    console.log('update')
  }, [userData, userLoading, moduleData, moduleLoading, dispatch ]);

  // const modules = moduleData?.module || [];
  return (
    <div className="container">
    <main>
      {/* <div>{moduleLoading ? <Spinner className="spinner" animation="grow" /> : <ModulesRender />}</div> */}
      <div>{moduleLoading ? <CloudSpinner /> : <ModulesRender />}</div>
    </main>
    <hr />
    </div>
  );
};

export default Home;
import React from 'react';
import { Link } from "react-router-dom";
import { ListGroup } from 'react-bootstrap';
import Auth from '../utils/auth';
import SectionsRender from './SectionsRender';

const LessonsRender = (props) => {
  const {
    lessons,
    moduleNumber
  } = props;

  if (!lessons.length) {
    return <h3>There are no lessons in this Module!</h3>;
  }
  const loggedIn = Auth.loggedIn();

  return (
    <div>
      {loggedIn ? ( 
      <>
      {lessons &&
        lessons.map((lesson) => (
          <div>
            <ListGroup className="col-auto" variant="flush">
            {/*<Link to={`/module/${moduleNumber}/lesson/${lesson.lessonNumber}`}>*/}
              <ListGroup.Item 
              variant="custom" 
              className="list-group-item-custom mx-2 my-2" 
              key={lesson.lessonNumber}
              >
              <small className="text-muted">Lesson Number: {lesson.lessonNumber}</small>
              <SectionsRender sections={lesson.lessonSection} moduleNum={moduleNumber} lessonNum={lesson.lessonNumber} />
              <br />
              </ListGroup.Item>
             {/* </Link>*/}
            </ListGroup>
          </div> 
        ))}
      </> ) : (
        <>
          {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
        </>
      )}
      </div>
  );
};

export default LessonsRender;
import React, { useState } from 'react'
import ToolTipCustom from './ToolTipCustom';
import { Button } from 'react-bootstrap';
import Auth from '../utils/auth';

const Footer = () => {
    const [buttonSubmit, setButtonSubmit] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const loggedIn = Auth.loggedIn();
    const donateButton = () => {
        setSubmitting(true);                      
        window.location.assign("https://discovery-payment-052022.herokuapp.com");
        // window.open("https://discovery-payment-052022.herokuapp.com", "_blank");
      }
    const ButtonStyle ={
        padding: "0.375rem 0.75rem",
        borderRadius: "0.25rem",
        border: "1px",
        color: "#343a40",
        backgroundColor: "#ffc107",
        borderColor: "#ffc107",
        width: "20%!important",
        display: "inline-block",
        fontWeight: "200",
        textAlign: "center",
        verticalAlign: "middle",
        fontSize: "0.5rem",
        lineHeight: "1.5"
    };
    return (
<footer>
            <ToolTipCustom
                placement='top'
                tooltipContent='New feature wish?'
                overlayContent={<a href="mailto:info@peppercorn.ai" className = 'footer-link'> Peppercorn Discovery </a>}
                tooltipVariant='custom'
                badgeVariant='clear'
            />
            <ToolTipCustom
                placement='top'
                tooltipContent='All Rights Reserved.'
                overlayContent='© Peppercorn Discovery'
                tooltipVariant='custom'
                badgeVariant='clear'
            />
                  {loggedIn ? 
                        (
                         <Button 
                              style={ButtonStyle}
                              type='link'
                              onClick={donateButton}
                              variant='success'
                              disabled = {buttonSubmit}
                          >{isSubmitting && <div><p>This will take a minute</p> <span className="spinner-border spinner-border-sm mr-1"></span></div>}
                            {buttonSubmit ? 'Thank you!! ✈ ' : 'Consider Donating'}
                          </Button>
                          )
                          : 
                          (<>

                           </>
                           )}
</footer>
    )
};
export default Footer;
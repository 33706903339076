import gql from 'graphql-tag';

export const QUERY_ME = gql`
  {
    me {
      _id
      username
      email
      role
      uniqueid
      completed 
      confirmed
      friends {
        _id
        username
      }
    }
  }
`;

export const GET_USER = gql`
query user($username: String!){
  user(username:$username){
    username
    _id
		email
    friends {
      _id
      username
    }
    confirmed
    completed
  }
}
`;

export const GET_ALL_MODULES = gql`

query modules {
  modules {
    _id
    moduleNumber
    moduleTitle
    moduleOverview
    modulePoster
    moduleCategory
    moduleVideo {
      _id
    }
    moduleLesson {
      lessonNumber
      lessonSection {
        sectionNumber
      }
    }
  }
}
`;

export const GET_ALL_MODULE_VIDEOS = gql`

query modules {
  modules {
    _id
    moduleNumber
    moduleTitle
    moduleVideo{
      _id
      videoNumber
      videoTitle
      videoContent
      videoOverview
      videoReleaseDate
    }
  }
}
`;

export const GET_SELECTED_MODULE = gql` 
query module($modNum: Int!){
  module(moduleNumber: $modNum){
    _id
    moduleNumber
    moduleTitle
    moduleOverview
    moduleReleaseDate
    modulePoster
    moduleCategory
    moduleVideo{
      _id
      videoNumber
      videoTitle
      videoContent
      videoOverview
      videoReleaseDate
    }
    moduleLesson{
      _id
      lessonNumber
      lessonTitle
      lessonOverview
      lessonReleaseDate
      lessonTime
      lessonSection{
        _id
        sectionTitle
        sectionNumber
      }
    }
  }
}
`;

export const GET_SELECTED_LESSON = gql` 
query lesson($lessNum: Int!){
  lesson(lessonNumber: $lessNum){
    _id
    lessonNumber
    lessonTitle
    lessonOverview
    lessonReleaseDate
    lessonTime
    lessonSection{
      _id
      sectionNumber
      sectionTitle
      sectionOverview
    }
  }
}
`;

export const GET_SELECTED_SECTION = gql` 
query section($sectNum: Int!){
  section(sectionNumber: $sectNum){
    _id
    sectionNumber
    sectionTitle
    sectionOverview
    sectionParagraph{
      _id
      paragraphRef
      paragraphNumber
      paragraphContent
      paragraphReleaseDate
      paragraphImage{
        _id
        imageNumber
        imageTitle
        imageContent
        imageOverview
        imageReleaseDate
      }
      paragraphVideo{
        _id
        videoNumber
        videoTitle
        videoContent
        videoOverview
        videoReleaseDate
      }
    }
  }
}
`;

export const GET_SELECTED_PARAGRAPH = gql` 
query paragraph($paraNum: Int!){
  paragraph(paragraphNumber: $paraInt){
      _id
      paragraphRef
      paragraphNumber
      paragraphContent
      paragraphReleaseDate
      paragraphImage{
        _id
        imageNumber
        imageTitle
        imageContent
        imageOverview
        imageReleaseDate
      }
      paragraphVideo{
        _id
        videoNumber
        videoTitle
        videoContent
        videoOverview
        videoReleaseDate
      }
  }
}
`;

export const GET_PARAGRAPHS = gql`
query paragraphs($paragraphRef: String) {
  paragraphs(paragraphRef: $paragraphRef) {
    _id
    paragraphNumber
  }
}
`;

export const SEARCH_QUERY = gql`
  query searchQuery($search: String!) {
    searchQuery(search: $search) {
      currentPage
      totalPages
      paragraphs {
        paragraphContent
        paragraphRef
      }
    }
  }
`;

// see SignupForm.js for comments
import React, { useState } from 'react';
import Auth from '../utils/auth';
import { Link } from 'react-router-dom';
import { Form, Button, Alert, Col, Row, Container } from 'react-bootstrap';
import { LOGIN } from '../utils/mutations';
import { useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const [login, {error} ] = useMutation(LOGIN);
    const [errorName, setErrorName] = useState('');
    const [formState, setFormState] = useState({ email: '', password: '' });
    const [passwordShown, setPasswordShown] = useState(false);
    const [validated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const eye = <FontAwesomeIcon icon={faEye} />;
   
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value
        });
    };

     // Show/ Hide password
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleFormSubmit = async event => {
        event.preventDefault();

        // check if form has everything (as per react-bootstrap docs)
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        try {
            const { data } = await login({
                variables: {...formState}
            });
            Auth.login(data.login.token);
        } catch (e) {
            setErrorName(`${e}`);
            console.error(e);
            setShowAlert(true);
           
        }

        setFormState({
            username: '',
            email: '',
            password: '',
        });
    };

    return (
        <div className="container">

        <Row>
            <Col className="logincard" md={{ span: 6, offset: 3}}>
            <Link className="btn btn-link" to="/signup">← Go to Sign Up</Link>
            <h3 className=" my-2 text-center">Login</h3>
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant='danger'>
                {errorName.split(":")[2]}
                </Alert>
                <Form.Group>
                    <Form.Label htmlFor='email'>Email</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Your email address'
                        name='email'
                        onChange={handleInputChange}
                        value={formState.email}
                        required
                    />
                    <Form.Control.Feedback type='invalid'>Email is required!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label htmlFor='password'>Password</Form.Label>
                    <div className="pass-wrapper">
                    <Form.Control
                        type={passwordShown ? 'text' : 'password'}
                        placeholder='Your password'
                        name='password'
                        onChange={handleInputChange}
                        value={formState.password}
                        required
                    />
                     <i className={passwordShown ? 'i-pass-show' : 'i-pass'} onClick={togglePasswordVisiblity}>{eye}</i>
                    </div>
                    <Form.Control.Feedback type='invalid'>Password is required!</Form.Control.Feedback>
                </Form.Group>
                <Button
                    disabled={!(formState.email && formState.password)}
                    type='submit'
                    variant='success'
                    className='w-100'
                    >
                    Login
                </Button>
                {error && <div>Login failed.</div>}
            </Form>
        
            <div className="form-group col text-right">
                <Link to={`login/forgot-password`} className="btn btn-link pr-0">Forgot Password →</Link>
            </div>
            <div>
            </div>
            </Col>
        </Row>
        </div>
    );
};

export default Login;
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardDeck} from 'react-bootstrap';
import Auth from '../utils/auth';
import { GET_SELECTED_MODULE } from '../utils/queries';
import { useQuery } from '@apollo/react-hooks';

const SearchItem = ({ search }) => {
  const { paragraphRef, paragraphContent } = search;
  const loggedIn = Auth.loggedIn();
  const moduleNum = Number(paragraphRef.split('.')[0]);
  const sectNum = Number(paragraphRef.split('.')[2]);
  const paraNum = Number(paragraphRef.split('.').join(''));

  //insert code here to identify the lessNum so it isnt hard-coded
  //for a sectNum find the associated Section._id.
  // map through all the lessons, if for a given lesson.lessionSection._id 
  //is equal to Section._id then save that lesson, and find that lession.lessionNumber
  //set lessNum = lession.lessionNumber
  // GraphQL API
  const { loading: loadingData, data: lessonData } = useQuery(GET_SELECTED_MODULE, {
    variables: { modNum: Number(moduleNum) }
  });
const module = lessonData?.module || [];

  const findlessNum = () => {
    let lessNum = 20;
    module.moduleLesson && module.moduleLesson.forEach(lesson => {
      lesson.lessonSection && lesson.lessonSection.forEach(section => {
        // if (paraNum !== (section.sectionNumber))
        if (sectNum !== (section.sectionNumber))
              {console.log("section not found", section.sectionNumber, lesson.lessonNumber)
                }
        else
              {lessNum = lesson.lessonNumber }
      })
    });
    return lessNum;
    };

  // end code. this can also be done as a lookup, if we have a JSON
 

  return (
    <div className="container">
    <div className="my-2">
      {loggedIn ? (
      <Row>
          <Col md={{ span: 6, offset: 3}}>
           <CardDeck>
            {/* <Link to={`/module/${moduleNum}/lesson/${findlessNum()}/section/${paraNum}`}> */}
            <Link to={`/module/${moduleNum}/lesson/${findlessNum()}/section/${sectNum}`}>
            <Card className="searchcard">
            <Card.Body>
              <Card.Title>
                Module: {moduleNum} ᐳ
                Lesson: {findlessNum()} ᐳ 
                Section: {sectNum}.
              </Card.Title>
              <Card.Text>
              <div className="dynamic-content-div" dangerouslySetInnerHTML= {{__html: paragraphContent.slice(0,300)}} />
              ...
            </Card.Text>
            </Card.Body>
            <Card.Footer>
               <small className="text-muted">Click to open this Section</small>
            </Card.Footer>
          </Card>
          </Link>
          <br />
          </CardDeck>
          </Col>
        </Row>
        ) : (
          <>
          {localStorage.setItem('referring_url', window.location.href)}
            <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of the page.</h4> 
          </>
        )}
    </div>
    </div>
  );
};

export default SearchItem;
import React from 'react';
import { Modal, Image, Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './modal.scss';

const ModalTutorial = (props) => {
  const { data } = props;

  const heading = {
    title: "Welcome to Peppercorn Discovery"
  }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
        <h3 className="py-4">{heading.title}</h3>
          <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
              {data.map((tutorial, index) => (
                <Nav.Item>
                <Nav.Link eventKey={index}>
                  {tutorial.tutorialTitle}
                </Nav.Link>
                </Nav.Item>
              ))}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
              {data.map((tutorial, index) => (
                <Tab.Pane eventKey={index}>
                <Image className="pt-4" width="250vw" src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${tutorial.tutorialPoster}`} alt={`tutorial-image:${tutorial.tutorialTitle}`} />
                  <article>{tutorial.tutorialOverview}</article>
                  {/* <Link className="d-flex justify-content-center py-2 px-2" to={`/${tutorial.tutorialLink}`}> */}
                  <Link className="d-flex justify-content-center py-2 px-2" activeClassName="activeRoute" onClick={() => window.open(`${tutorial.tutorialLink}`, "_blank")}>
                    <button className="btn-modal">Go to {tutorial.tutorialTitle}</button>
                  </Link>
                </Tab.Pane>
              ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
          <div className="py-4 d-flex justify-content-center">
                
          </div>
        </Modal.Body>
      </Modal>
    );
  };

export default ModalTutorial;
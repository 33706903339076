import React from 'react';
import { Accordion, Card} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { GET_SELECTED_MODULE } from '../../utils/queries';
import { useQuery } from '@apollo/react-hooks';

const SidebarSectionAccordion = (props) => {
  const {
    activesections,
    activemoduleNum,
    activelessonNum,
    activesectionNum
  } = props;


  //insert code here to identify the lessNum so it isnt hard-coded
  //for a sectNum find the associated Section._id.
  // map through all the lessons, if for a given lesson.lessionSection._id 
  //is equal to Section._id then save that lesson, and find that lession.lessionNumber
  //set lessNum = lession.lessionNumber
  // GraphQL API
  const { loading: loadingData, data: lessonData } = useQuery(GET_SELECTED_MODULE, {
    variables: { modNum: Number(activemoduleNum) }
  });
  const module = lessonData?.module || [];

  const findlessNum = (num) => {
    let lessNum = 20;
    module.moduleLesson && module.moduleLesson.forEach(lesson => {
      lesson.lessonSection && lesson.lessonSection.forEach(section => {
        if (num !== (section.sectionNumber))
              {console.log("section not found", section.sectionNumber, lesson.lessonNumber)
                }
        else
              {lessNum = lesson.lessonNumber }
      })
    });
    return lessNum;
    };

  // end code. this can also be done as a lookup, if we have a JSON

  if (!activesections.length) {
    return <h1> Module?</h1>;
  };

  const checkModuleColor = (num) => {
    let compareNum =  parseInt(activesectionNum, 10);
    let checkActiveModule = (compareNum === num);
    let sidebarAccordionStyle;
    {checkActiveModule 
      ?
          sidebarAccordionStyle = {
          padding: "0rem 0rem",
          marginBottom: "0",
          backgroundColor: "rgba(0,0,0,.1)",
          borderBottom: "1px solid rgba(0,0,0,.125)"
        }
        
    :
          sidebarAccordionStyle = {
          padding: "0rem 0rem",
          marginBottom: "0",
          backgroundColor: "rgba(0,0,0,0)",
          borderBottom: "1px solid rgba(0,0,0,0)"
        }
     
      }
      return sidebarAccordionStyle;
    };



  return (
    <Accordion className="card-sidebarmodule">
  {activesections && activesections.map((section) => (
    <Card className="card-sidebarmodule" key={section._id} >
      
      <Accordion.Toggle 
        as={Card.Header} 
        eventKey={`${section._id}`} 
        style={(checkModuleColor(section.sectionNumber))}
        >
            Section {section.sectionNumber} 
            {' '} 
            {' '}  
            <i className="fas fa-caret-right"></i>                                                                                                                                                                                                                                                                                                                                                                                                                           
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={`${section._id}`}>
        <>
          <Link className = 'cardsidebarmodule-link' to={`/module/${activemoduleNum}/lesson/${findlessNum(section.sectionNumber)}/section/${section.sectionNumber}`}>
                <a>{section.sectionTitle}</a>
          </Link>
        </>
      </Accordion.Collapse>
    </Card>
  ))}
</Accordion>
  );
};

export default SidebarSectionAccordion;
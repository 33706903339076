import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { withRouter, useHistory } from "react-router-dom";

const SearchForm = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMsg, setErrorMsg] = useState('🔍 Search a term');

  let history = useHistory();

  const handleInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };

  const handleSearch = (event) => {
    event.preventDefault();

    if (searchTerm.trim() !== '') {
        // console.log('Search term:', searchTerm)
        //Link to search page
        history.push(`/search/${searchTerm}`);
    } else {
      setErrorMsg('Enter a term...');
    }
  };

  return (
    <div>
        <div className="my-2">
      <Form inline onSubmit={handleSearch}>
        
        <Form.Group controlId="formSearch">
          <Form.Control
            type="search"
            name="searchTerm"
            value={searchTerm}
            placeholder={errorMsg}
            onChange={handleInputChange}
            autoComplete="on"
            alt="magifying glass icon for searching"
          />
        </Form.Group>
      </Form>
      </div>
    </div>
  );
};

export default withRouter(SearchForm);
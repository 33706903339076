import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser ($username: String!, $email: String!, $password: String!, $uniqueid: String!) {
  addUser (username: $username, email:$email, password: $password, uniqueid: $uniqueid){
    user {
      _id
      username
      uniqueid
      password
      role
    }
  }
}
`;


export const UPDATE_USER = gql`
mutation($email: String, $username: String,$password: String, $uniqueid: String){
  updateUser(email: $email, username: $username, password: $password, uniqueid: $uniqueid){
    email
    username
    password
    uniqueid
    completed
  }
}
`

export const CONFIRM_USER = gql`
mutation confirmUser($confirmed: Boolean!, $idToken: String!) {
  confirmUser(confirmed: $confirmed, idToken:$idToken){
    token
    user{
      confirmed
    }
  }
}
`

export const FORGOT_PASSWORD = gql`
mutation forgotPassword($email: String!) {
  forgotPassword(email: $email){
    email
  }
}
`

export const RECOVER_PASSWORD = gql`
mutation recoverPassword($email: String!, $password: String!) {
  recoverPassword(email: $email, password: $password){
    username
  }
}
`

